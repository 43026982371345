// link resolver
// requires: type, uid
// as the project grows, this function should be updated according to the routes.

exports.linkResolver = page => {
  if (page.type === 'holding_page') {
    const path = `/${page.uid === 'home' ? '' : page.uid}`
    return path
  }

  // pagination
  if (page.pageNumber >= 2) {
    return `/${page.uid}/${page.pageNumber}`
  }

  // fallback route
  return `/${page.uid}`
}
